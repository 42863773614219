<template>
  <div class="login">
    <div class="centerContent">
      <section class="formContainer">
        <div class="formTop twocol">
          <h2>Login In To Your Account</h2>
        </div>
        <div class="formBody">
          <form :class="{ disabled: isLoging }">
            <!-- email -->
            <div class="field position-r">
              <label class="lineLable">Email</label>
              <input
                type="email"
                v-model="email"
                required
                class="fullWidth"
                :class="{ error: !email_valid }"
              />
              <span class="validation" v-if="!email_valid"
                >Please enter your email</span
              >
            </div>
            <!-- password -->
            <div class="field position-r">
              <label class="lineLable">Password</label>
              <input
                :type="pswdType"
                v-model="password"
                required
                class="fullWidth"
                :class="{ error: !email_valid }"
              />
              <span
                class="icon fixRight iconBtn"
                v-show="password.length > 0 && !pswdVisible"
                @click="togglePassword"
                ><font-awesome-icon icon="eye"
              /></span>
              <span
                class="icon fixRight iconBtn"
                v-show="password.length > 0 && pswdVisible"
                @click="togglePassword"
                @keyup.enter="userLogin"
                ><font-awesome-icon icon="eye-slash"
              /></span>
              <span class="validation" v-if="!password_valid"
                >Please enter your password</span
              >
            </div>
            <div class="field twocol">
              <!-- <label class="sm-text checkbox-label">
                <input type="checkbox" v-model="remember" />
                Remember me
              </label> -->
              <a-checkbox v-model="remember"> Remember me </a-checkbox>
              <router-link :to="{ name: 'forgot-password' }" class="sm-text"
                >Forgot Password</router-link
              >
            </div>

            <div class="failBG sm-text" v-if="msg !== ''" v-html="msg"></div>

            <button class="button button--blue" @click.prevent="userLogin">
              LOGIN
            </button>
            <div class="formBody__sso">
              <span>or</span>
              <a class="button button--microsoft" :href="microsoftLoginURL">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                  <path fill="#f35325" d="M0 0h10v10H0z" />
                  <path fill="#81bc06" d="M11 0h10v10H11z" />
                  <path fill="#05a6f0" d="M0 11h10v10H0z" />
                  <path fill="#ffba08" d="M11 11h10v10H11z" />
                </svg>
                LOGIN WITH MICROSOFT</a
              >
            </div>
            <div
              class="failBG failBG--sso sm-text"
              v-if="ssoErrorMessages[ssoError]"
            >
              {{ ssoErrorMessages[ssoError] }}
            </div>
            <p class="formBody__signup">
              No account?
              <router-link :to="{ name: 'signup' }" class="blueTextLink"
                >Sign up now.</router-link
              >
            </p>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import { bus } from "@/main.js";
export default {
  data() {
    return {
      pswdVisible: false,
      password: "",
      email: "",
      password_valid: true,
      email_valid: true,
      msg: "",
      remember: false,
      isLoging: false,
      microsoftLoginURL: "",
      ssoError: null,
      ssoErrorMessages: [
        "Something went wrong (Database error)",
        "Invalid tenant Id or tenant Id does not exists",
        "Unauthorized request from Microsoft.",
      ],
    };
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
  },
  methods: {
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    userLogin() {
      this.password_valid = this.password.length > 0 ? true : false;
      this.email_valid = this.email.length > 0 ? true : false;

      if (this.password_valid && this.email_valid) {
        this.isLoging = true;
        const loginPromise = new Promise((resolve) => {
          wsUtils.UserLogin(resolve, {
            email: this.email,
            password: this.password,
          });
        });
        loginPromise.then((data) => {
          this.isLoging = false;
          if (
            data.AccountType !== null &&
            data.firstName !== null &&
            data.UserType !== null &&
            data.AccountName !== null &&
            data.AccountPaymentArrangement !== null
          ) {
            this.loginComplete(data);
          } else {
            this.$router.push({
              name: "global-error-page",
              params: { id: "WS1UL_01", userId: data.EncrypteduserID },
            });
          }
        });
      }
    },
    loginComplete(data) {
      if (data.userID !== 0) {
        // window.studioUser = data;

        this.setSkin(data.SkinIdentifier);

        if (this.remember) {
          jsUtils.setCookie("HS-STUDIO", JSON.stringify(data), 3);
        } else {
          jsUtils.setCookie("HS-STUDIO", JSON.stringify(data), 0.5);
        }

        localStorage.setItem("studioUser", JSON.stringify(data));

        let userType = data.AccountType.toLowerCase();

        setTimeout(() => {
          bus.$emit("login");
          let toRouterPath = jsUtils.getToRouterPath();

          if (toRouterPath !== "undefined") {
            this.$router.push(toRouterPath).catch(() => {
              this.userRedirection(userType);
            });
          } else {
            this.userRedirection(userType);
          }
          localStorage.removeItem("toRouterPath");
        }, 200);
      } else {
        if (data.Status === 2) {
          this.msg =
            "Before you can log in, your account needs to be confirmed. Please check your inbox for instructions on how to complete the registration process. If you can't find the email in your Inbox, please check your Junk/SPAM folder or contact support for assistance.";
        } else if (data.Status === 3 || data.Status === 0) {
          this.msg =
            '<p>The email or password you entered did not match our records. Please double-check and try again.</p><p>If you can\'t remember your password, you can use the "Forgot password" function to reset your password.</p>';
        }
      }
    },
    userRedirection(userType) {
      if (
        userType === "agency" ||
        userType === "enterprise" ||
        userType === "master/hotspex"
      ) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/");
      }
    },
    setSkin(skinClass) {
      if (skinClass !== "") {
        setTimeout(() => {
          document.body.classList.add(skinClass.toLowerCase(), "login");
        }, 200);
      }
    },
  },
  mounted() {
    jsUtils.clearSetting();

    let account = jsUtils.readCookie("HS-STUDIO");
    if (account) {
      bus.$emit("login");
      this.$router.push("/");
    }
    this.ssoError = jsUtils.readCookie("SSOError");

    if (this.ssoError !== null) {
      jsUtils.deleteCookie("SSOError");
    }
  },
  created() {
    if (window.location.hostname === "app.hotspexlabs.com") {
      this.microsoftLoginURL =
        "http://sso.hotspexlabs.com/?ssoaction=login&appid=azure-ad";
    } else {
      this.microsoftLoginURL =
        "http://sso.hotspexlabs.com/?ssoaction=login&appid=azure-ad";
    }
    jsUtils.loadLangObj("videoAuthorLang", "/tooltip/videoAuthor.json");
  },
  activated() {
    document.body.classList.remove("login");
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: url("../../public/images/logBG2.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  background-attachment: fixed;
  display: grid;

  height: 100%;
}

form {
  margin: 25px 0;
}

form.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.failBG {
  margin-bottom: 30px;
  color: red;
  padding: 10px;
  border-radius: 5px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--sso {
    margin: 15px 0 0;
  }
}

.field {
  .iconInput {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
  }

  .iconInput:focus-within .icon {
    background-color: var(--hsblue);
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    transition: background-color 0.5s;
    margin-right: -4px;
  }

  .icon.fixRight {
    background-color: transparent !important;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }

  .validation {
    font-size: 12px;
    color: red;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
</style>
